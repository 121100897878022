@import '../../../../scss/theme-bootstrap';

.footer-language-toggle {
  > span {
    &:after {
      position: relative;
      content: '|';
      top: -1px;
    }
    &:last-child:after {
      content: none;
    }
    .lang-abbr {
      text-transform: uppercase;
    }
    .lang-full {
      display: none;
    }
  }
}
